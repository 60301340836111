<script setup lang="ts">
import { computed } from "vue";
import { RouterLink } from "vue-router";
import { useStore } from "@/store";
import AvatarPhoto from "./AvatarPhoto.vue";

const store = useStore();
const user = computed(() => store.state.user);
const displayName = computed(() => store.getters.displayName);
</script>

<template>
  <div
    v-if="user?.loggedIn && user?.data && user?.meta"
    class="flex flex-shrink-0 bg-gray-700 p-4"
  >
    <router-link to="/profile" class="group block w-full flex-shrink-0">
      <div class="flex items-center justify-start">
        <AvatarPhoto :user="user.meta" class="h-9 w-9" />
        <div class="ml-3">
          <p v-if="displayName" class="text-sm font-medium text-white">
            {{ displayName }}
          </p>
          <p
            class="text-xs font-medium text-gray-300 group-hover:text-gray-200"
          >
            View profile
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>
