<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { format } from "date-fns";
import db, { getTeams } from "@/firebase/util";
import Heading from "@/components/ui/HeadingUI.vue";

import type { Team, Season } from "@/types/sesc";
import { useStore } from "@/store";

const today = format(new Date(), "EEEE");
const teamsPracticingToday = ref<Team[]>([]);

watchEffect(async () => {
  const teams = await getTeams();
  if (teams.length > 0) {
    teamsPracticingToday.value = teams.filter((team) => {
      if (!(team.currentSeason as Season)?.practiceDays?.length) {
        return false;
      } else {
        return (team.currentSeason as Season)?.practiceDays?.some(
          (practice) => practice.day === today,
        );
      }
    });
  }
});
</script>
<template>
  <div>
    <Heading size="text-xl" color="text-white">Teams Practicing Today</Heading>
    <p class="text-white">Who is practicing today and where?</p>
    <ul class="mt-4 bg-white rounded p-5">
      <li
        v-for="team in teamsPracticingToday"
        :key="team.id"
        class="list-item mb-8 last:mb-0"
      >
        <Heading size="text-lg">{{ team.name }}</Heading>
        <ul
          v-for="practice in (
            team?.currentSeason as Season
          )?.practiceDays?.filter((practice) => practice.day === today)"
          :key="practice.day"
          class="mt-2"
        >
          <li class="text-lg">{{ practice.locationName }}</li>
          <li class="text-sm uppercase italic">{{ practice.time }}</li>
        </ul>
      </li>
    </ul>
  </div>
</template>
