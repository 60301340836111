import { createApp } from "vue";
import { VueFire, VueFireAuth } from "vuefire";
import App from "./App.vue";
import Vue3ColorPicker from "vue3-colorpicker";
import VueClipboard from "vue3-clipboard";
import Popper from "vue3-popper";
import UseCSVButton from "@usecsv/vuejs3";
import "vue3-colorpicker/style.css";
import VueSmoothScroll from "vue3-smooth-scroll";
import router from "./router";
import { firebaseApp } from "./firebase/config";
import { store, key } from "./store";

import "./assets/index.css";

const app = createApp(App);

app
  .use(VueFire, { firebaseApp, modules: [VueFireAuth()] })
  .use(router)
  .use(Vue3ColorPicker)
  .use(UseCSVButton as any)
  .use(VueSmoothScroll, {
    updateHistory: false,
  })
  .use(store, key)
  .use(VueClipboard, { autoSetContainer: true, appendToBody: true });

app.component("PopperUi", Popper);

app.mount("#app");
