<script lang="ts">
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>
<script setup lang="ts">
import type { User, Player } from "@/types/sesc";

defineProps<{ user?: User; player?: Player }>();
</script>
<template>
  <div v-if="user">
    <img
      v-if="user.image"
      class="rounded-full max-w-none"
      :src="user.image"
      :alt="`${user.displayName} profile picture`"
      v-bind="$attrs"
    />
    <div
      v-else
      class="mx-auto inline-flex flex-shrink-0 flex-grow-0 items-center justify-center rounded-full bg-gray-500"
      v-bind="$attrs"
    >
      <span class="text-xl font-medium leading-none text-white">{{
        `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`
      }}</span>
    </div>
  </div>
  <div v-else-if="player">
    <img
      v-if="player.photo"
      class="rounded-full max-w-none"
      :src="player.photo"
      :alt="`${player.firstName} photo`"
      v-bind="$attrs"
    />
    <span
      v-else
      class="mx-auto inline-flex flex-shrink-0 flex-grow-0 items-center justify-center rounded-full bg-gray-500"
      v-bind="$attrs"
    >
      <span class="text-xl font-medium leading-none text-white">{{
        `${player.firstName.charAt(0)}${player.lastName.charAt(0)}`
      }}</span>
    </span>
  </div>
</template>
