import parse from "date-fns/parse";
import format from "date-fns/format";
import type { Timestamp } from "firebase/firestore";
import {
  differenceInCalendarYears,
  differenceInYears,
  add,
  isBefore,
  differenceInCalendarDays,
} from "date-fns";

export const validEmail = (email: string): boolean => {
  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return expression.test(email);
};

export const formatDate = (
  date: string,
  formatStr = "MMMM d, yyyy"
): string => {
  return format(parse(date, "yyyy-MM-dd", new Date()), formatStr);
};

export const displayTimestamp = (
  date: Timestamp,
  formatStr = "MM/dd/yyyy"
): string => {
  return format(date.toDate(), formatStr);
};

export const currentTeamLevel = ({
  birthYear,
  gender,
  asNumber,
}: {
  birthYear: string;
  gender?: string;
  asNumber?: boolean;
}): string | number => {
  const thisYear = Number(format(new Date(), "yyyy"));
  const thisMonth = Number(format(new Date(), "M"));

  function genderInitial() {
    if (gender !== undefined) {
      switch (gender) {
        case "Male":
          return "B";
        case "Female":
          return "G";
        default:
          return "";
      }
    }
  }

  if (thisMonth > 5) {
    const age = thisYear - Number(birthYear) + 1;
    if (asNumber) return age;
    return `U${age}${genderInitial()}`;
  } else {
    const age = thisYear - Number(birthYear);
    if (asNumber) return age;
    return `U${age}${genderInitial()}`;
  }
};

export const playerAge = (dateOfBirth: Timestamp): string => {
  // TODO calculate current age based on today
  const birthdayDate = dateOfBirth.toDate();
  const today = new Date();
  return `${differenceInYears(today, birthdayDate)}`;
};

export const daysUntilBirthday = (dateOfBirth: Timestamp): number => {
  const birthdayDate = dateOfBirth.toDate();
  const today = new Date();
  const calendarYearsFromBirthdate = differenceInCalendarYears(
    today,
    birthdayDate
  );
  const birthdayThisYear = add(birthdayDate, {
    years: calendarYearsFromBirthdate,
  });
  if (isBefore(birthdayThisYear, today)) {
    // birthday already happened, count days until next years birthday
    const nextYearsBirthday = add(birthdayThisYear, { years: 1 });
    const daysUntilBirthday = differenceInCalendarDays(
      nextYearsBirthday,
      today
    );
    return daysUntilBirthday;
  } else {
    // birthday is coming up this year
    const daysUntilBirthday = differenceInCalendarDays(birthdayThisYear, today);
    return daysUntilBirthday;
  }
};

export function lightOrDark(color: string): "black" | "white" | null {
  // Variables for red, green, blue values
  let r: number, g: number, b: number;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    const colorArr = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    if (!colorArr || colorArr.length < 1) return null;

    r = parseInt(colorArr[1]);
    g = parseInt(colorArr[2]);
    b = parseInt(colorArr[3]);
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    const colorNum = +("0x" + color.slice(1));

    r = colorNum >> 16;
    g = (colorNum >> 8) & 255;
    b = colorNum & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "black";
  } else {
    return "white";
  }
}
