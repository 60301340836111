import {
  createStore,
  useStore as baseUseStore,
  type Store as VuexStore,
  type CommitOptions,
  type DispatchOptions,
} from "vuex";

import type { InjectionKey } from "vue";
import { type State, state } from "./store/state";
import { type Getters, getters } from "./store/getters";
import { type Mutations, mutations } from "./store/mutations";
import { type Actions, actions } from "./store/actions";

export const key: InjectionKey<VuexStore<State>> = Symbol();

export const store = createStore<State>({
  state,
  getters,
  mutations,
  actions,
});

export type Store = Omit<
  VuexStore<State>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export function useStore() {
  return baseUseStore(key) as Store;
}
