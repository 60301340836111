<script setup lang="ts">
import { useRouter } from "vue-router";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import NavMenu from "./NavMenu.vue";
import NavProfileLink from "./ui/NavProfileLink.vue";
import logo from "@/assets/sesc-logo.svg";

import type { Nav } from "@/types/sesc";

const emit = defineEmits(["closeSidebar"]);

const props = defineProps<{
  sidebarOpen: boolean;
  navigation: Nav[];
}>();

const router = useRouter();

router.afterEach(() => {
  if (props.sidebarOpen) {
    emit("closeSidebar");
  }
});
</script>

<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog
      as="div"
      class="relative z-50 md:hidden"
      @close="$emit('closeSidebar')"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel
            class="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="$emit('closeSidebar')"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div
              class="h-screen flex flex-col justify-between flex-1 overflow-y-auto pt-5 pb-4"
            >
              <NavMenu :navigation="navigation" />
              <div class="flex flex-shrink-0 items-center px-4 my-8">
                <img class="h-auto w-48 mx-auto" :src="logo" alt="SESC" />
              </div>
            </div>
            <NavProfileLink />
          </DialogPanel>
        </TransitionChild>
        <div class="w-14 flex-shrink-0">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
