<script setup lang="ts">
import { ref, computed } from "vue";
import { limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import db from "@/firebase/util";
import { format, parse } from "date-fns";

import VeoLogo from "@/assets/veo-logo-dark.svg";
import Veo1Image from "@/assets/veo-1.jpg";
import Veo2Image from "@/assets/veo-2.jpg";

import type { VeoReservation } from "@/types/sesc";

const cam1Reservation = ref<VeoReservation[]>([]);
const cam2Reservation = ref<VeoReservation[]>([]);

const today = computed(() => format(new Date(), "yyyy-MM-dd"));

const cam1Query = query(
  db.reservations,
  where("date", "<=", today.value),
  where("camera", "==", "1"),
  limit(1),
  orderBy("date", "desc")
);
onSnapshot(cam1Query, (cam1snapshot) => {
  cam1snapshot.forEach((reservation) => {
    cam1Reservation.value.push({ id: reservation.id, ...reservation.data() });
  });
});

const cam2Query = query(
  db.reservations,
  where("date", "<=", today.value),
  where("camera", "==", "2"),
  limit(1),
  orderBy("date", "desc")
);
onSnapshot(cam2Query, (cam2snapshot) => {
  cam2snapshot.forEach((reservation) => {
    cam2Reservation.value.push({ id: reservation.id, ...reservation.data() });
  });
});
</script>
<template>
  <div>
    <div class="flex flex-col sm:flex-row lg:flex-col 2xl:flex-row gap-4">
      <div
        v-if="cam1Reservation.length > 0"
        class="overflow-hidden bg-white shadow sm:rounded-lg"
      >
        <div class="px-4 py-5 sm:px-6">
          <h3
            class="flex gap-2 items-center text-lg font-medium leading-6 text-gray-900"
          >
            <img :src="VeoLogo" alt="Veo Logo" /> Camera 1
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            <img :src="Veo1Image" alt="Veo 1 Camera" />
          </p>
        </div>
        <div class="border-t border-gray-200 py-2">
          <dl>
            <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dd
                class="font-bold mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
              >
                {{ cam1Reservation[0].contact.name }}
              </dd>
            </div>
            <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ cam1Reservation[0].contact.email }}
              </dd>
            </div>
            <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ cam1Reservation[0].contact.phone }}
              </dd>
            </div>
            <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{
                  format(
                    parse(cam1Reservation[0].date, "yyyy-MM-dd", new Date()),
                    "EEEE, MMMM dd, yyyy"
                  )
                }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div
        v-if="cam2Reservation.length > 0"
        class="overflow-hidden bg-white shadow sm:rounded-lg"
      >
        <div class="px-4 py-5 sm:px-6">
          <h3
            class="flex gap-2 items-center text-lg font-medium leading-6 text-gray-900"
          >
            <img :src="VeoLogo" alt="Veo Logo" /> Camera 2
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            <img :src="Veo2Image" alt="Veo 1 Camera" />
          </p>
        </div>
        <div class="border-t border-gray-200 py-2">
          <dl>
            <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dd
                class="font-bold mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
              >
                {{ cam2Reservation[0].contact.name }}
              </dd>
            </div>
            <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ cam2Reservation[0].contact.email }}
              </dd>
            </div>
            <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{ cam2Reservation[0].contact.phone }}
              </dd>
            </div>
            <div class="px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {{
                  format(
                    parse(cam2Reservation[0].date, "yyyy-MM-dd", new Date()),
                    "EEEE, MMMM dd, yyyy"
                  )
                }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
