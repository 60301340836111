<script setup lang="ts"></script>
<template>
  <div
    class="border-b border-gray-300 pb-5 sm:flex sm:items-end sm:justify-between"
  >
    <div class="flex-shrink">
      <h1 class="text-2xl font-bold leading-6 text-gray-900">
        <slot></slot>
      </h1>
      <p class="mt-2 max-w-4xl text-sm text-gray-500 pr-8">
        <slot name="description"></slot>
      </p>
    </div>

    <div class="mt-3 flex sm:mt-0 sm:ml-4">
      <slot name="actions"></slot>
    </div>
  </div>
</template>
