import {
  VideoCameraIcon,
  HomeIcon,
  UsersIcon,
  ClipboardIcon,
  CalendarDaysIcon,
  UserGroupIcon,
} from "@heroicons/vue/24/outline";
import type { Nav } from "@/types/sesc";

export const navigation: Nav[] = [
  { name: "Dashboard", href: "/", icon: HomeIcon, current: true },
  {
    name: "VeoCam",
    href: "/veo",
    icon: VideoCameraIcon,
    current: false,
    children: [
      {
        name: "Reserve a Veo",
        href: "/veo/reserve",
        current: false,
      },
      {
        name: "My Reservations",
        href: "/veo/reservations",
        current: false,
      },
      {
        name: "VeoCam Tutorial",
        href: "/veo/tutorial",
        current: false,
      },
    ],
  },
  { name: "Teams", href: "/teams", icon: UserGroupIcon, current: false },
  {
    name: "Practice Schedules",
    href: "/practice-schedules",
    icon: CalendarDaysIcon,
    current: false,
  },
  {
    name: "Coaches",
    href: "/coaches",
    icon: ClipboardIcon,
    current: false,
  },
  // { name: "Players", href: "/players", icon: ChartBarIcon, current: false },
  // { name: "Club Documents", href: "#", icon: FolderIcon, current: false },
  // { name: "Club Accounts", href: "/users", icon: UsersIcon, current: false },
];
export default navigation;
