<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "@/store";
import NavMenu from "./NavMenu.vue";
import NavProfileLink from "./ui/NavProfileLink.vue";
import logo from "@/assets/sesc-logo.svg";
import spring from "@/assets/Spring.png";
import winter from "@/assets/Winter.png";
import fall from "@/assets/Fall.png";
import summer from "@/assets/Summer.png";

import type { Nav } from "@/types/sesc";

defineProps<{
  navigation: Nav[];
}>();

const store = useStore();
const settings = computed(() => store.state.app);
const background = computed(() => {
  if (settings.value?.currentSeason) {
    switch (settings.value.currentSeason.toLowerCase()) {
      case "spring":
        return spring;
      case "summer":
        return summer;
      case "winter":
        return winter;
      case "fall":
        return fall;
    }
  }
  return "";
});
</script>

<template>
  <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col z-[90]">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div
      class="flex min-h-0 flex-1 flex-col bg-gray-900"
      :style="{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundBlendMode: 'overlay',
      }"
    >
      <div
        class="flex flex-1 flex-col overflow-y-auto pt-5 pb-4 bg-gradient-to-b from-gray-800 to-transparent"
      >
        <div class="flex flex-shrink-0 items-center px-4">
          <img class="h-auto w-48 mx-auto" :src="logo" alt="SESC" />
        </div>
        <NavMenu :navigation="navigation" />
      </div>
      <NavProfileLink />
    </div>
  </div>
</template>
