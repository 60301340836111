<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRouter, RouterLink } from "vue-router";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import {
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
  PowerIcon,
} from "@heroicons/vue/24/outline";
import NavAdmin from "./NavAdmin.vue";
import NavDirector from "./NavDirector.vue";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/action-types";

import type { Nav } from "@/types/sesc";

defineProps<{
  navigation: Nav[];
}>();

interface DisclosureStates {
  [key: string]: boolean;
}

const DSLS = "sesc-nav-disclosure-states";

const store = useStore();

const user = computed(() => store.state.user);
const isAdminUser = computed(() => user.value.isAdmin);
const isDirectorUser = computed(() => user.value.isDirector);
const disclosureStates = ref<DisclosureStates>({});

const router = useRouter();
onMounted(() => {
  const disclosureStatesLS = window.localStorage.getItem(DSLS);
  if (disclosureStatesLS) {
    const dslsObj = JSON.parse(disclosureStatesLS);
    if (dslsObj) {
      disclosureStates.value = dslsObj;
    }
  }
});

function saveDisclosureState(itemHref: string, open: boolean) {
  const initialValue = disclosureStates.value ? disclosureStates.value : {};
  initialValue[itemHref] = !open;
  window.localStorage.setItem(DSLS, JSON.stringify(initialValue));
}

async function logout() {
  store.dispatch(ActionTypes.LOG_OUT, undefined);
  router.push("/login");
}
</script>
<template>
  <nav v-if="user.loggedIn" class="mt-5 md:flex-1 space-y-1 px-2">
    <template v-for="item in navigation" :key="item.name">
      <div v-if="!item.children">
        <router-link
          :to="item.href"
          :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
          ]"
        >
          <component
            :is="item.icon"
            :class="[
              item.current
                ? 'text-gray-300'
                : 'text-gray-400 group-hover:text-gray-300',
              'mr-3 flex-shrink-0 h-6 w-6',
            ]"
            aria-hidden="true"
          />
          {{ item.name }}
        </router-link>
      </div>
      <Disclosure
        as="div"
        v-else
        class="space-y-1"
        v-slot="{ open }"
        :default-open="disclosureStates[item.href]"
      >
        <DisclosureButton
          @click="saveDisclosureState(item.href, open)"
          :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500',
          ]"
        >
          <component
            :is="item.icon"
            :class="[
              item.current
                ? 'text-gray-300'
                : 'text-gray-400 group-hover:text-gray-300',
              'mr-3 flex-shrink-0 h-6 w-6',
            ]"
            aria-hidden="true"
          />
          <span class="flex-1">{{ item.name }}</span>
          <svg
            :class="[
              open ? 'text-gray-400 rotate-90' : 'text-gray-300',
              'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
            ]"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
          </svg>
        </DisclosureButton>
        <DisclosurePanel class="space-y-1">
          <DisclosureButton
            v-for="subItem in item.children"
            :key="subItem.name"
            :as="RouterLink"
            :to="subItem.href"
            :class="[
              subItem.current
                ? 'bg-gray-900 text-white'
                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              'group w-full flex items-center pl-11 pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500',
            ]"
            >{{ subItem.name }}</DisclosureButton
          >
        </DisclosurePanel>
      </Disclosure>
    </template>
    <NavAdmin v-if="isAdminUser" />
    <NavDirector v-if="isDirectorUser" />
    <div class="border-gray-500 border-t border-b py-2">
      <router-link
        to="/profile"
        :class="[
          'text-gray-300 hover:bg-gray-700 hover:text-white',
          'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
        ]"
      >
        <UserCircleIcon
          :class="[
            'text-gray-400 group-hover:text-gray-300',
            'mr-3 flex-shrink-0 h-6 w-6',
          ]"
          aria-hidden="true"
        />
        Profile
      </router-link>
      <a
        @click.prevent="logout"
        :class="[
          'text-gray-300 hover:bg-gray-700 hover:text-white',
          'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer',
        ]"
        ><PowerIcon class="flex-shrink-0 h-6 w-6 mr-3" />Log out</a
      >
    </div>
  </nav>
  <nav v-else class="mt-5 flex-1 space-y-1 px-2">
    <a
      href="/login"
      class="hover:bg-gray-700 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
    >
      <ArrowRightOnRectangleIcon
        class="text-gray-300 mr-3 flex-shrink-0 h-6 w-6"
        aria-hidden="true"
      />
      Login
    </a>
  </nav>
</template>
