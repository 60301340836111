import { MutationTypes } from "./mutation-types";
import { state as defaultState } from "./state";
import type { User as FBUser } from "firebase/auth";
import type { MutationTree } from "vuex";
import type { State } from "./state";
import type {
  User,
  VeoReservation,
  Team,
  Alert,
  AppSetting,
  Player,
} from "@/types/sesc";

export type Mutations<S = State> = {
  [MutationTypes.SET_LOGGED_IN](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_ADMIN](state: S, payload: boolean): void;
  [MutationTypes.SET_IS_DIRECTOR](state: S, payload: boolean): void;
  [MutationTypes.SET_USER](state: S, payload: FBUser | null): void;
  [MutationTypes.SET_USER_META](state: S, payload: User | null): void;
  [MutationTypes.SET_USER_RESERVATIONS](
    state: S,
    payload: VeoReservation[],
  ): void;
  [MutationTypes.SET_RESERVATION_DATE](
    state: S,
    payload: string | Date | null,
  ): void;
  [MutationTypes.SET_RESERVATION_CAMERA](state: S, payload: string): void;
  [MutationTypes.SET_TEAM](state: S, payload: Team): void;
  [MutationTypes.SET_PLAYER](state: S, payload: Player): void;
  [MutationTypes.SET_PLAYERS](state: S, payload: Player[]): void;
  [MutationTypes.SET_ALERT](state: S, payload: Alert): void;
  [MutationTypes.SET_APP_SETTINGS](state: S, payload: AppSetting): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_LOGGED_IN](state, value) {
    state.user.loggedIn = value;
  },
  [MutationTypes.SET_IS_ADMIN](state, value) {
    state.user.isAdmin = value;
  },
  [MutationTypes.SET_IS_DIRECTOR](state, value) {
    state.user.isDirector = value;
  },
  [MutationTypes.SET_USER](state, data) {
    if (data === null) {
      // if user is null, unload all user data
      state.user = { ...defaultState.user };
    } else {
      state.user.data = data;
      state.user.id = data?.uid;
    }
  },
  [MutationTypes.SET_USER_META](state, meta) {
    state.user.meta = meta;
  },
  [MutationTypes.SET_USER_RESERVATIONS](state, reservations) {
    state.user.reservations = reservations;
  },
  [MutationTypes.SET_RESERVATION_DATE](state, date) {
    state.reservation.date = date;
  },
  [MutationTypes.SET_RESERVATION_CAMERA](state, camera) {
    state.reservation.camera = camera;
  },
  [MutationTypes.SET_TEAM](state, team) {
    state.team = team;
  },
  [MutationTypes.SET_PLAYER](state, player) {
    state.player = player;
  },
  [MutationTypes.SET_PLAYERS](state, players) {
    state.players = players;
  },
  [MutationTypes.SET_ALERT](state, alert) {
    state.alert = { show: true, ...alert };
  },
  [MutationTypes.SET_APP_SETTINGS](state, settings) {
    state.app = settings;
  },
};
