import type { User as FBUser } from "firebase/auth";
import type {
  User,
  VeoReservation,
  Team,
  Alert,
  AppSetting,
  Player,
} from "@/types/sesc";

export interface UserState {
  id: string;
  loggedIn: boolean;
  isAdmin: boolean;
  isDirector: boolean;
  data: FBUser | null;
  meta: User | null;
  reservations: VeoReservation[] | null;
}

export interface ReservationState {
  date: string | Date | null;
  camera: string;
}
export interface State {
  user: UserState;
  reservation: ReservationState;
  team: Team;
  teams: Team[];
  player: Player;
  players: Player[];
  alert: Alert;
  app: AppSetting;
}

export const state: State = {
  user: {
    id: "",
    loggedIn: false,
    isAdmin: false,
    isDirector: false,
    data: null,
    meta: null,
    reservations: null,
  },
  reservation: {
    date: null,
    camera: "",
  },
  team: {
    name: "",
    gender: "",
    birthYear: "",
    birthYearShort: "",
    color: "",
  },
  player: {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
  },
  players: [],
  alert: {
    show: false,
    title: "",
    message: "",
    error: false,
  },
  app: {},
};
