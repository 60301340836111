export enum ActionTypes {
  // Veo Reservations
  CHANGE_STEP = "CHANGE_STEP",
  RESET_RESERVATION = "RESET_RESERVATION",
  DELETE_RESERVATION = "DELETE_RESERVATION",
  GET_USER_RESERVATIONS = "GET_USER_RESERVATIONS",
  // Auth
  REGISTER = "REGISTER",
  LOG_IN_WITH_PASSWORD = "LOG_IN_WITH_PASSWORD",
  LOG_IN_WITH_GOOGLE = "LOG_IN_WITH_GOOGLE",
  LOG_OUT = "LOG_OUT",
  // Manage User Data
  FETCH_USER = "FETCH_USER",
  IS_USER_ADMIN = "IS_USER_ADMIN",
  REFRESH_USER = "REFRESH_USER",
  GET_USER_META = "GET_USER_META",
  // Manage Teams and Coaches data
  // GET_TEAMS = "GET_TEAMS",
  EDIT_TEAM = "EDIT_TEAM",
  DELETE_TEAM = "DELETE_TEAM",
  // Manage Players
  GET_PLAYERS = "GET_PLAYERS",
  EDIT_PLAYER = "EDIT_PLAYER",
  DELETE_PLAYER = "DELETE_PLAYER",
  ADD_PLAYER_TO_TEAM = "ADD_PLAYER_TO_TEAM",
  REMOVE_PLAYER_FROM_TEAM = "REMOVE_PLAYER_FROM_TEAM",
  // App
  GET_APP_SETTINGS = "GET_APP_SETTINGS",
  UPDATE_APP_SETTINGS = "UPDATE_APP_SETTINGS",
}
