<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { RouterView, RouterLink, useRoute, useRouter } from "vue-router";
import { useCurrentUser } from "vuefire";
import { fetchAndActivate } from "firebase/remote-config";
import { Bars3Icon } from "@heroicons/vue/24/outline";
import { remoteConfig } from "./firebase/config";
import { navigation } from "./router/nav";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/action-types";
import NavMobile from "./components/NavMobile.vue";
import NavDesktop from "./components/NavDesktop.vue";
import AlertUi from "./components/ui/AlertUi.vue";
import FeedbackUi from "./components/FeedbackUi.vue";
import SESCLogo from "./assets/sesc-logo.svg";

import type { Nav, ChildNav } from "@/types/sesc";

const sidebarOpen = ref(false);
const appLoaded = ref(false);

const router = useRouter();
const route = useRoute();
const store = useStore();
const user = useCurrentUser();

watch(user, async (currentUser, previousUser) => {
  if (!currentUser && previousUser && route.meta.requiresAuth) {
    return router.push({ name: "login" });
  }
  if (currentUser && !appLoaded.value) {
    await fetchAndActivate(remoteConfig);
    try {
      await store.dispatch(ActionTypes.FETCH_USER, currentUser);
    } catch (err) {
      console.error(err);
    }
    appLoaded.value = true;
  }
  if (currentUser && typeof route.query.redirect === "string") {
    return router.push(route.query.redirect);
  }
});

function closeSidebar() {
  sidebarOpen.value = false;
}

const nav = computed(() => {
  return navigation.map((navItem: Nav) => {
    if (navItem.children) {
      navItem.children = navItem.children.map((subItem: ChildNav) => {
        return { ...subItem, current: route.path == subItem.href };
      });
    }
    return { ...navItem, current: route.path == navItem.href };
  });
});
</script>

<template>
  <div>
    <AlertUi />
    <NavMobile
      v-if="user"
      :navigation="nav"
      :sidebarOpen="sidebarOpen"
      @closeSidebar="closeSidebar"
    />

    <!-- Static sidebar for desktop -->
    <NavDesktop v-if="user" :navigation="nav" />
    <div class="flex flex-1 flex-col" :class="user ? 'md:pl-64' : ''">
      <div
        v-if="user"
        class="sticky top-0 z-10 bg-gray-100 dark:bg-gray-800 pl-1 pt-1 md:hidden flex justify-between items-center"
      >
        <button
          type="button"
          class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <router-link
          to="/"
          class="block text-white text-sm tracking-wide shadow-sm"
        >
          <img
            :src="SESCLogo"
            class="w-16 h-16 absolute left-1/2 top-2 -ml-8"
            alt="SESC COACH'S PORTAL"
          />
        </router-link>
        <button
          type="button"
          class="-mr-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <main
        class="flex-1 bg-gradient-to-b from-gray-100 to-gray-200 min-h-screen pb-12"
      >
        <router-view v-slot="{ Component }">
          <transition name="slide" mode="out-in">
            <component :is="Component" :key="$route.path"></component>
          </transition>
        </router-view>
      </main>
    </div>
    <FeedbackUi v-if="user" />
  </div>
</template>

<style lang="css">
.slide-enter-active,
.slide-leave-active {
  transition:
    opacity 250ms,
    transform 250ms;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(30%);
}
</style>
