<script setup lang="ts">
import { computed } from "vue";

import type { Team, Season, PracticeDay } from "@/types/sesc";
import { format, parse } from "date-fns";

const props = defineProps<{ team: Team }>();

const practiceDays = (props.team?.currentSeason as Season)?.practiceDays;

const nextPracticeDay = computed<PracticeDay | null>((): PracticeDay | null => {
  if (practiceDays) {
    let filteredPracticeDays: PracticeDay[];
    const today = format(new Date(), "i");
    filteredPracticeDays = practiceDays.filter((practiceDay) => {
      const dayOfTheWeek = format(
        parse(practiceDay.day, "EEEE", new Date()),
        "i"
      );
      return dayOfTheWeek >= today;
    });
    if (filteredPracticeDays.length === 0) {
      filteredPracticeDays = [...practiceDays];
    }
    return filteredPracticeDays.sort(
      (a, b) =>
        Number(format(parse(a.day, "EEEE", new Date()), "i")) -
        Number(format(parse(b.day, "EEEE", new Date()), "i"))
    )[0] as PracticeDay;
  }
  return null;
});
</script>
<template>
  <div v-if="nextPracticeDay">
    {{ nextPracticeDay.day }}, {{ nextPracticeDay.time }} @
    {{ nextPracticeDay.locationName }}
  </div>
</template>
