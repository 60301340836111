<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { useStore } from "@/store";
import HeaderBar from "@/components/ui/HeaderBar.vue";
import DashboardTeamsListItem from "./DashboardTeamsListItem.vue";
import { getTeams } from "@/firebase/util";

import type { Team } from "@/types/sesc";

const store = useStore();

const teams = ref<Team[] | null>(null);
const userTeams = ref<Team[]>([]);

watchEffect(async () => {
  teams.value = await getTeams();
  console.log(teams.value);
});

watchEffect(() => {
  if (store.state.user && teams.value) {
    const { id } = store.state.user;
    if (id) {
      const thisUsersTeams = teams.value.reduce(
        (teamsArr, team) => {
          if (team.coachesUidList && team.coachesUidList.includes(id)) {
            teamsArr.push({ ...team });
          }
          return teamsArr;
        },
        <Team[]>[],
      );
      userTeams.value = thisUsersTeams;
    }
  }
});
</script>
<template>
  <div v-if="userTeams && userTeams.length">
    <HeaderBar>
      <span class="text-white text-lg">Your Teams</span>
    </HeaderBar>
    <ul role="list" class="mt-6 grid grid-cols-1 gap-8 items-stretch">
      <DashboardTeamsListItem
        v-for="team in userTeams"
        :team="team"
        :key="team.id"
      />
    </ul>
  </div>
</template>
