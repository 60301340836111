<script setup lang="ts">
import { computed, ref, reactive, watchEffect } from "vue";
import { httpsCallable } from "@firebase/functions";
import { functions } from "@/firebase/config";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { format } from "date-fns";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { EnvelopeOpenIcon } from "@heroicons/vue/24/outline";
import { useStore } from "@/store";
import TextArea from "./ui/inputs/TextArea.vue";

const initialState = {
  content: "",
};

const state = reactive(initialState);

const rules = computed(() => {
  return {
    content: { required },
  };
});

const v$ = useVuelidate(rules, state);

const store = useStore();

const user = computed(() => store.state.user);

const open = ref(false);
const sending = ref(false);
const submitted = ref(false);

const dayOfTheYear = computed(() =>
  parseInt(format(new Date(), "D", { useAdditionalDayOfYearTokens: true }))
);
const lastSubmission = window.localStorage.getItem("sesc-submissions");
const submissionsRef = ref(0);

watchEffect(() => {
  if (lastSubmission) {
    submissionsRef.value = parseInt(lastSubmission);
  }
});

function resetAndOpen() {
  state.content = "";
  submitted.value = false;
  open.value = true;
}

async function handleSubmit() {
  v$.value.$validate();
  if (
    !v$.value.error &&
    user.value.meta?.displayName &&
    user.value.meta?.email
  ) {
    sending.value = true;
    const sendFeedback = httpsCallable(functions, "http-feedback-sendFeedback");
    await sendFeedback({
      name: user.value.meta?.displayName,
      email: user.value.meta?.email,
      content: state.content,
    });
    submitted.value = true;
    sending.value = false;
    window.localStorage.setItem("sesc-submissions", `${dayOfTheYear.value}`);
    submissionsRef.value = dayOfTheYear.value;
  }
}
</script>
<template>
  <div>
    <button
      v-if="dayOfTheYear > submissionsRef"
      @click="resetAndOpen"
      class="hidden md:block fixed top-0 right-2 z-[1000] bg-gray-800 text-white font-bold uppercase py-1 pt-2 px-4 rounded-b text-xs hover:pt-4 transition-all"
    >
      Provide Feedback
    </button>

    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-[1001]" @close="open = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 md:ml-64"
              >
                <form @submit.prevent="handleSubmit">
                  <div>
                    <div
                      class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                    >
                      <EnvelopeOpenIcon
                        class="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div v-if="!submitted" class="mt-3 text-center sm:mt-5">
                      <DialogTitle
                        as="h3"
                        class="text-lg font-medium leading-6 text-gray-900"
                        >Submit Feedback</DialogTitle
                      >
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          Have a question? Is something not working? Use this
                          form to submit feedback and questions to the SESC
                          webmaster.
                        </p>
                      </div>
                      <div class="mt-2 text-left">
                        <div
                          class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6"
                        >
                          <div class="col-span-6">
                            <TextArea
                              v-model="state.content"
                              id="content"
                              name="content"
                              label="Message"
                              :v="v$.content"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="mt-3 text-center sm:mt-5">
                      <DialogTitle
                        as="h3"
                        class="text-lg font-medium leading-6 text-gray-900"
                        >Submission received!</DialogTitle
                      >
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          Thank you for your feedback.
                        </p>
                      </div>
                      <div class="mt-2">
                        <button
                          type="button"
                          @click="open = false"
                          class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!submitted"
                    class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
                  >
                    <button
                      type="submit"
                      :disabled="sending"
                      class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    >
                      Submit Feedback
                    </button>
                    <button
                      type="button"
                      class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm disabled:opacity-50 disabled:pointer-events-none disabled:cursor-default"
                      @click="open = false"
                      ref="cancelButtonRef"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
