import { sortBy, isEmpty } from "lodash";

import type { GetterTree } from "vuex";
import type { State } from "./state";
import type { VeoReservation, Team } from "@/types/sesc";

export type Getters = {
  nextReservation(state: State): VeoReservation | null;
  displayName(state: State): string;
  avatar(state: State): string;
};

export const getters: GetterTree<State, State> & Getters = {
  nextReservation(state): VeoReservation | null {
    if (isEmpty(state.user.reservations)) return null;
    const sortedReservations = sortBy(state.user.reservations, ["date"]);
    return sortedReservations[0];
  },
  displayName(state) {
    return (
      state?.user?.meta?.displayName || state?.user?.data?.displayName || ""
    );
  },
  avatar(state) {
    return state?.user?.meta?.image || state?.user?.data?.photoURL || "";
  },
};
