<script setup lang="ts">
export interface Props {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "responsive";
}
const props = withDefaults(defineProps<Props>(), {
  size: "responsive",
});
</script>
<template>
  <button
    v-if="props.size === 'xs'"
    type="button"
    class="whitespace-nowrap inline-flex items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
  >
    <slot></slot>
  </button>
  <button
    v-else-if="props.size === 'sm'"
    type="button"
    class="whitespace-nowrap inline-flex items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
  >
    <slot></slot>
  </button>
  <button
    v-else-if="props.size === 'md'"
    type="button"
    class="whitespace-nowrap inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
  >
    <slot></slot>
  </button>
  <button
    v-else-if="props.size === 'lg'"
    type="button"
    class="whitespace-nowrap inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
  >
    <slot></slot>
  </button>
  <button
    v-else-if="props.size === 'xl'"
    type="button"
    class="whitespace-nowrap inline-flex items-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
  >
    <slot></slot>
  </button>
  <button
    v-else
    type="button"
    class="whitespace-nowrap inline-flex items-center rounded-md border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs sm:px-3 sm:py-2 sm:text-sm md:px-4 md:py-2 md:text-sm lg:px-4 lg:py-2 lg:text-base xl:px-6 xl:py-3 xl:text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
  >
    <slot></slot>
  </button>
</template>
