<script setup lang="ts">
import { computed } from "vue";
import { format } from "date-fns";
import { useStore } from "@/store";
import HeaderBar from "@/components/ui/HeaderBar.vue";
import DashboardLinks from "@/components/DashboardLinks.vue";
import DashboardVeo from "@/components/DashboardVeo.vue";
import DashboardTeams from "@/components/DashboardTeams.vue";
import AdminDashboard from "@/components/AdminDashboard.vue";
import backgroundImage from "@/assets/background1.jpg";

const store = useStore();
const isAdmin = computed(() => store.state.user.isAdmin);
const displayName = computed(() => store.getters.displayName);
</script>

<template>
  <div
    class="h-full min-h-screen bg-no-repeat bg-cover"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
  >
    <div class="h-full min-h-screen bg-gradient-to-b from-[rgba(0,0,0,0.8)]">
      <div class="py-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 space-y-6">
        <HeaderBar class="sm:items-center">
          <span v-if="displayName" class="text-white"
            >Welcome, {{ displayName }}!</span
          >
          <template #actions>
            <span class="text-white text-xs lg:text-base">
              {{ format(new Date(), "EEEE, MMMM do, yyyy") }}
            </span>
          </template>
        </HeaderBar>
        <main>
          <div v-if="isAdmin">
            <AdminDashboard />
          </div>
          <div v-else class="py-2 gap-8 xl:gap-16 grid grid-cols-6">
            <div class="order-2 col-span-6 lg:order-1 lg:col-span-3">
              <DashboardLinks />
            </div>
            <div class="order-1 col-span-6 lg:order-2 lg:col-span-3 space-y-16">
              <div class="grid grid-cols-6">
                <DashboardVeo class="order-2 col-span-6 lg:order-1 lg:mb-8" />
                <DashboardTeams
                  class="order-1 mb-8 col-span-6 lg:order-2 lg:mb-0"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
