export enum MutationTypes {
  SET_LOGGED_IN = "SET_LOGGED_IN",
  SET_IS_ADMIN = "SET_IS_ADMIN",
  SET_IS_DIRECTOR = "SET_IS_DIRECTOR",
  SET_USER = "SET_USER",
  SET_USER_META = "SET_USER_META",
  SET_USER_RESERVATIONS = "SET_USER_RESERVATIONS",
  SET_RESERVATION_DATE = "SET_RESERVATION_DATE",
  SET_RESERVATION_CAMERA = "SET_RESERVATION_CAMERA",
  SET_TEAM = "SET_TEAM",
  SET_PLAYER = "SET_PLAYER",
  SET_PLAYERS = "SET_PLAYERS",
  SET_ALERT = "SET_ALERT",
  SET_APP_SETTINGS = "SET_APP_SETTINGS",
}
