<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import HeaderBar from "./ui/HeaderBar.vue";
import ButtonUi from "./ui/ButtonUi.vue";
import DashboardVeoReservation from "./ui/DashboardVeoReservation.vue";

const router = useRouter();
const store = useStore();

const nextReservation = computed(() => store.getters.nextReservation);
</script>
<template>
  <div>
    <HeaderBar
      ><span class="text-white text-lg shadow">Next VeoCam Reservation</span>
      <template #actions>
        <ButtonUi @click="router.push('/veo/reserve')"
          >Make Reservation</ButtonUi
        >
      </template></HeaderBar
    >
    <div class="mt-3">
      <ul v-if="nextReservation" role="list" class="w-full grid grid-cols-1">
        <DashboardVeoReservation :reservation="nextReservation" />
      </ul>
      <div v-else>
        <p class="text-sm text-gray-100">No Upcoming Reservations</p>
      </div>
    </div>
  </div>
</template>
